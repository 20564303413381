define('due-backoffice/models/company', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _emberDataModelFragments, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    // ReadOnly attrs
    name: (0, _attr.default)({ readOnly: true }),
    dashboard_banner: (0, _attr.default)({ readOnly: true }),
    subscription_status: (0, _attr.default)({ readOnly: true }),
    live_accounts_count: (0, _attr.default)('number', { readOnly: true }),
    live_surveys_count: (0, _attr.default)('number', { readOnly: true }),
    live_company_integrations_count: (0, _attr.default)('number', { readOnly: true }),
    feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    completed_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    last_7_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    last_7_completed_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    nps: (0, _attr.default)('number', { readOnly: true }),
    avg: (0, _attr.default)('number', { readOnly: true }),
    last_7_nps: (0, _attr.default)('number', { readOnly: true }),
    last_7_avg: (0, _attr.default)('number', { readOnly: true }),
    verbatim_translated: (0, _attr.default)('number', { readOnly: true }),
    unqualified_segments_count: (0, _attr.default)('number', { readOnly: true }),

    // Editables attrs
    trial_start: (0, _attr.default)('date'),
    not_powered_by_due: (0, _attr.default)('number'),
    max_account: (0, _attr.default)('number'),
    max_sms_per_feedback: (0, _attr.default)('number'),
    max_digests_limit: (0, _attr.default)('number'),
    verbatim_translation_threshold: (0, _attr.default)('number'),
    custom_sending_domain: (0, _attr.default)('string'),
    custom_sending_local: (0, _attr.default)('string'),
    custom_bounce_domain: (0, _attr.default)('string'),
    saml_sso_url: (0, _attr.default)('string'),
    saml_metadata_url: (0, _attr.default)('string'),
    saml_certificate: (0, _attr.default)('string'),
    show_answer_details: (0, _attr.default)('boolean'),
    show_custom_stats: (0, _attr.default)('boolean'),
    show_verbatim: (0, _attr.default)('boolean'),
    show_analysis: (0, _attr.default)('boolean'),
    show_due_digests: (0, _attr.default)('boolean'),
    anonymize_alerts: (0, _attr.default)('boolean'),
    show_keywords_adjustment: (0, _attr.default)('boolean'),
    show_public_reviews: (0, _attr.default)('boolean'),
    is_favourite: (0, _attr.default)('boolean'),
    is_test_company: (0, _attr.default)('boolean'),
    verbatim_translation: (0, _attr.default)('boolean'),
    verbatim_auto_translation: (0, _attr.default)('boolean'),
    is_blocked: (0, _attr.default)('boolean'),
    managed_segments: (0, _attr.default)('boolean'),
    saml_enabled: (0, _attr.default)('boolean'),
    replicate_to_china: (0, _attr.default)('boolean'),
    enable_new_survey_dashboard: (0, _attr.default)('number'),
    enable_new_survey_respondant: (0, _attr.default)('number'),
    hide_email_in_export: (0, _attr.default)('boolean'),
    short_url_sms: (0, _attr.default)('boolean'),
    ch_report_enabled: (0, _attr.default)('boolean'),
    ff_standard_report: (0, _attr.default)('boolean'),
    enable_summaries: (0, _attr.default)('boolean'),

    custom_settings: _emberDataModelFragments.default.fragment('custom-setting'),

    // RelationShips
    subscription_plan: (0, _relationships.belongsTo)('subscription-plan'),
    default_rating_scale: (0, _relationships.belongsTo)('rating-scale'),
    default_closed_questions_rating_scale: (0, _relationships.belongsTo)('rating-scale'),
    custom_stats: (0, _relationships.hasMany)('custom-stat'),
    accounts: (0, _relationships.hasMany)('account'),
    surveys: (0, _relationships.hasMany)('survey'),
    company_integrations: (0, _relationships.hasMany)('company-integration'),
    dashboard_languages: (0, _relationships.hasMany)('dashboard-language'),
    company_notifications: (0, _relationships.hasMany)('company-notification'),

    // Computeds
    customStatCount: Ember.computed('custom_stats.[]', function () {
      return this.hasMany('custom_stats').ids().length;
    }),

    subscriptionStatusColor: computed('subscription_plan.name', function () {
      switch (this.get('subscription_plan.name')) {
        case 'trial':
          return 'trial';
        case 'unicorn':
          return 'unicorn';
        default:
          return 'green';
      }
    }),

    endOfTrial: computed('trial_start', function () {
      var trial_end = (0, _moment.default)(this.get('trial_start')).add(14, 'days');
      return trial_end.diff((0, _moment.default)(), 'days');
    }),

    endOfTrialPhrase: computed('endOfTrial', function () {
      var result = this.get('endOfTrial');
      return isNaN(result) ? 'not started' : result.toString() + "j";
    }),

    subscription_detail: computed('subscription_plan.name', 'subscription_plan.decimal_amount', 'endOfTrial', function () {
      switch (this.get('subscription_plan.name')) {
        case 'trial':
          return 'trial (' + this.get('endOfTrialPhrase') + ')';
        case 'unicorn':
          return 'licorn';
        default:
          return this.get('subscription_plan.decimal_amount') + ' \u20AC';
      }
    }),

    isWarning: computed('last_7_feedbacks_count', 'subscription_plan.name', function () {
      return this.get('last_7_feedbacks_count') === 0 && this.get('subscription_plan.name') !== 'trial';
    })

  });
});