define('due-backoffice/components/survey/show-page', ['exports', 'due-backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var computed = Ember.computed,
      copy = Ember.copy,
      debounce = Ember.run.debounce;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    dueApiQuery: Ember.inject.service(),
    session: Ember.inject.service(),

    childClassNames: 'ui large list',
    textOptionsDisplay: 'hidden',

    segmentsError: [],
    forceDisplayMappingTableKey: false,
    mappingTableOptions: null,
    feedbackImports: null,
    listedColumns: null,
    triggerDropDownReset: false,
    generateFeedbacksLoading: false,
    resetFeedbacksLoading: false,
    generate_number_feedbacks: 1000,
    generate_start_date: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
    generate_end_date: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
    disableUpload: '',
    surveyType: 'offline',
    surveyTypes: ['offline', 'online'],
    deliveryType: 'email',
    deliveryTypes: ['email', 'sms', 'no_dispatch', 'popin', 'gmb'],

    init: function init() {
      var _this2 = this;

      this.get('store').query('company-mapping-table', { filter: { company_id: this.get('model.company_id') } }).then(function (tables) {
        _this2.set('mappingTableOptions', tables.toArray().length === 0 ? [] : tables.get('firstObject').get('content.headers'));
      });
      this.feedback_imports();
      this.get('store').findRecord('feedback-import-column', this.get('model.id')).then(function (listedColumns) {
        _this2.set('listedColumnsSaved', listedColumns.data.list);
      });
      this._super.apply(this, arguments);
    },
    feedback_imports: function feedback_imports() {
      var _this3 = this;

      this.get('store').query('feedback_import', { filter: { survey_id: this.get('model.id') } }).then(function (imports) {
        _this3.set('feedbackImports', imports);
      });
    },


    has_embedded_nps: Ember.computed('model.has_embedded_nps', {
      get: function get() {
        return this.get('model.has_embedded_nps');
      },
      set: function set(_, value) {
        this.set('model.has_embedded_nps', value);
        if (value) {
          this.set('model.closed_questions_after_recommendation', true);
          this.set('model.gather_nps_open_questions', false);
        }
        this.get('model').save();
        return value;
      }
    }),

    has_custom_html: Ember.computed('model.has_custom_html', {
      get: function get() {
        return this.get('model.has_custom_html');
      },
      set: function set(_, value) {
        this.set('model.has_custom_html', value);
        return value;
      }
    }),

    need_nps_confirmation: Ember.computed('model.need_nps_confirmation', {
      get: function get() {
        return this.get('model.need_nps_confirmation');
      },
      set: function set(_, value) {
        this.set('model.need_nps_confirmation', value);
        if (!value) {
          this.set('model.can_edit_nps_rate', value);
        }
        this.get('model').save();
        return value;
      }
    }),

    can_edit_nps_rate: computed('model.can_edit_nps_rate', {
      get: function get() {
        return this.get('model.can_edit_nps_rate');
      },
      set: function set(_, value) {
        this.set('model.can_edit_nps_rate', value);
        this.get('model').save();
        return value;
      }
    }),

    gather_nps_open_questions: computed('model.gather_nps_open_questions', {
      get: function get() {
        return this.get('model.gather_nps_open_questions');
      },
      set: function set(_, value) {
        this.set('model.gather_nps_open_questions', value);
        if (value) {
          this.set('model.closed_questions_after_comment', true);
          this.set('model.closed_questions_after_recommendation', true);
          this.set('model.has_embedded_nps', false);
        }
        this.get('model').save();
        return value;
      }
    }),

    closed_questions_after_recommendation: computed('model.closed_questions_after_recommendation', {
      get: function get() {
        return this.get('model.closed_questions_after_recommendation');
      },
      set: function set(_, value) {
        this.set('model.closed_questions_after_recommendation', value);
        if (!value) {
          this.set('model.has_embedded_nps', false);
          this.set('model.gather_nps_open_questions', false);
        }
        this.get('model').save();
        return value;
      }
    }),

    closed_questions_after_comment: computed('model.closed_questions_after_comment', {
      get: function get() {
        return this.get('model.closed_questions_after_comment');
      },
      set: function set(_, value) {
        this.set('model.closed_questions_after_comment', value);
        if (!value) {
          this.set('model.gather_nps_open_questions', false);
        }
        this.get('model').save();
        return value;
      }
    }),

    auto_tagging_enabled: Ember.computed('model.auto_tagging_enabled', {
      get: function get() {
        return this.get('model.auto_tagging_enabled');
      },
      set: function set(_, value) {
        this.set('model.auto_tagging_enabled', value);
        this.get('model').save();
        return value;
      }
    }),

    hide_thanks: Ember.computed('model.hide_thanks', {
      get: function get() {
        return this.get('model.hide_thanks');
      },
      set: function set(_, value) {
        this.set('model.hide_thanks', value);
        this.get('model').save();
        return value;
      }
    }),

    no_logo: Ember.computed('model.no_logo', {
      get: function get() {
        return this.get('model.no_logo');
      },
      set: function set(_, value) {
        this.set('model.no_logo', value);
        this.get('model').save();
        return value;
      }
    }),

    alternative_nps: Ember.computed('model.has_alternative_rating_scale', {
      get: function get() {
        return this.get('model.has_alternative_rating_scale');
      },
      set: function set(_, value) {
        this.set('model.has_alternative_rating_scale', value);
        this.get('model').save();
        return value;
      }
    }),

    gather_public_reviews: Ember.computed('model.gather_public_reviews', {
      get: function get() {
        return this.get('model.gather_public_reviews');
      },
      set: function set(_, value) {
        this.set('model.gather_public_reviews', value);
        this.get('model').save();
        return value;
      }
    }),

    hide_dispatch_statistics: Ember.computed('model.hide_dispatch_statistics', {
      get: function get() {
        return this.get('model.hide_dispatch_statistics');
      },
      set: function set(_, value) {
        this.set('model.hide_dispatch_statistics', value);
        this.get('model').save();
        return value;
      }
    }),

    enable_close_the_loop: Ember.computed('model.enable_ctl', {
      get: function get() {
        return this.get('model.enable_ctl');
      },
      set: function set(_, value) {
        this.set('model.enable_ctl', value);
        this.get('model').save();
        return value;
      }
    }),

    hide_open_question_numbering: Ember.computed('model.hide_open_question_numbering', {
      get: function get() {
        return this.get('model.hide_open_question_numbering');
      },
      set: function set(_, value) {
        this.set('model.hide_open_question_numbering', value);
        this.get('model').save();
        return value;
      }
    }),

    hide_all_questions_numbering: Ember.computed('model.hide_all_questions_numbering', {
      get: function get() {
        return this.get('model.hide_all_questions_numbering');
      },
      set: function set(_, value) {
        this.set('model.hide_all_questions_numbering', value);
        this.get('model').save();
        return value;
      }
    }),

    client_unique_segment: Ember.computed('model.client_unique_segment', {
      get: function get() {
        return this.get('model.client_unique_segment');
      },
      set: function set(_, value) {
        if (value == "") {
          this.set('model.client_unique_segment', null);
        }
        this.set('model.client_unique_segment', value);
        this.get('model').save();
        return value;
      }
    }),

    hide_button_shadow: Ember.computed('model.hide_button_shadow', {
      get: function get() {
        return this.get('model.hide_button_shadow');
      },
      set: function set(_, value) {
        this.set('model.hide_button_shadow', value);
        this.get('model').save();
        return value;
      }
    }),

    taggingDate: Ember.computed('model.tagging_start_date', {
      get: function get() {
        return moment(this.get('model.tagging_start_date')).format('YYYY-MM-DD');
      },
      set: function set(_, value) {
        if (value === "") {
          this.set('model.tagging_start_date', null);
        }
        this.set('model.tagging_start_date', value);
        this.get('model').save();
        return value;
      }
    }),

    cooldown: Ember.computed('model.cooldown', {
      get: function get() {
        return this.get('model.cooldown');
      },
      set: function set(_, value) {
        if (value == "") {
          this.set('model.cooldown', null);
        }
        this.set('model.cooldown', value);
        this.get('model').save();
        return value;
      }
    }),

    canSaveRatings: computed('model.hasDirtyAttributes', function () {
      var changedBelongsTo = this.get('model').changedBelongsTo();
      if (changedBelongsTo.closed_questions_rating_scale || changedBelongsTo.rating_scale) {
        return;
      }
      return 'disabled';
    }),

    canUploadImport: computed('selectedHeaders', function () {
      var selectedHeaders = this.get('selectedHeaders');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(selectedHeaders)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          if (!value) {
            return 'disabled';
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return '';
    }),

    needSaveRatings: computed('canSaveRatings', function () {
      if (this.get('canSaveRatings') == 'disabled') {
        return '';
      }
      return 'disabled';
    }),

    orderedSegmentPickers: computed('model.segment_pickers.@each.position', function () {
      return this.get('model.segment_pickers').sortBy('position');
    }),

    isSaving: computed('model.isSaving', 'next_active_revision_is_loading', function () {
      if (this.get('model.isSaving') || this.get('next_active_revision_is_loading')) {
        return 'loading';
      }
    }),

    customConsentTextObjects: computed('model.languages', 'model.consent_text', function () {
      var _this4 = this;

      return this.get('model.languages').map(function (language) {
        return { tag: language.get('tag').toLowerCase(), consentText: _this4.get('model.consent_texts') ? _this4.get('model.consent_texts')[language.get('tag').toLowerCase()] : '' };
      });
    }),

    mapping_table_enabled: Ember.computed('model.mapping_table_key', 'forceDisplayMappingTableKey', {
      get: function get() {
        return this.get('model.mapping_table_key') !== null || this.get('forceDisplayMappingTableKey');
      },
      set: function set(_, value) {
        if (value == true && this.get('model.mapping_table_key') === null) {
          this.set('forceDisplayMappingTableKey', true);
          return true;
        }
        this.set('model.mapping_table_key', null);
        this.get('model').save();
        return value;
      }
    }),

    mappingTableSelectedKey: Ember.computed('model.mapping_table_key', {
      get: function get() {
        return this.get('model.mapping_table_key');
      },
      set: function set(_, value) {
        this.set('model.mapping_table_key', value);
        this.get('model').save();
        return value;
      }
    }),

    _computeText: function _computeText(consentTextObject) {
      var consentObject = this.get('model.consent_texts') || {};
      consentObject[consentTextObject.tag] = consentTextObject.consentText;
      this.set('model.consent_texts', consentObject);
    },
    _verifySegments: function _verifySegments() {
      var _this5 = this;

      var consentObject = this.get('model.consent_texts');
      var segments = [];
      var segRegex = /\{segment:\s*(.*?)\s*\}/g;
      var matches = [];

      for (var tag in consentObject) {
        while (matches == segRegex.exec(consentObject[tag])) {
          consentObject[tag] = consentObject[tag].replace(/\{segment:\s*/g, '{segment:');
          if (matches) segments.push(matches[1].trim());
        }
      }
      this.set('model.consent_text', consentObject);
      this.get('store').query('segment', { filter: { names: segments.uniq() } }).then(function (returnedSegments) {
        var segmentsError = [];
        segments.uniq().forEach(function (segment) {
          if (returnedSegments.filter(function (s) {
            return s.get('name') === segment;
          }).length === 0) {
            segmentsError.push(segment);
          }
        });
        _this5.set('segmentsError', segmentsError);
      }).then(function () {
        if (_this5.get('segmentsError').length === 0) {
          _this5.get('model').save();
        }
      });
    },
    handleError: function handleError(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('feedbackUploadError', errorText);
      this.set('feedbackUploadUploading', false);
    },


    feedback_import: null,
    feedbackUploadError: null,
    feedbackUploadSaved: null,
    feedbackUploadUploading: null,

    feedbackUpload: function feedbackUpload() {
      var _this6 = this;

      var csv = this.get('feedbackImportFile');
      if (!csv) {
        return RSVP.reject('empty_file');
      }

      this.set('feedbackUploadError', null);
      this.set('feedbackImportError', null);
      this.set('feedbackUploadSaved', null);
      this.set('feedbackUploadUploading', true);
      this.set('listedColumns', copy(this.get('listedColumnsSaved'), true));
      return this.get('store').createRecord('feedback_import', {
        file_file_name: $(document.getElementById('upload-csv-input').getElementsByTagName('input')[0]).prop('files')[0].name,
        file_raw_data: this.get('feedbackImportFile'),
        survey_id: this.get('model.id'),
        company_id: this.get('model.company_id')
      }).save().then(function (response) {
        _this6.set('feedback_import', response);
        _this6.fillHeaders();
        _this6.set('feedbackUploadSaved', true);
        _this6.set('modalIsOpened', true);
        return response;
      }).catch(function (response) {
        _this6.set('feedbackUploadError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>"));
        if (_this6.get('feedbackImportFile')) {
          var input = $('#upload-csv-input')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      }).finally(function () {
        _this6.set('feedbackUploadUploading', false);
      });
    },
    fillHeaders: function fillHeaders() {
      var _this7 = this;

      var selectedHeaders = {};
      var listedColumns = this.get('listedColumns');
      var headers = this.get('feedback_import.headers');

      var _loop = function _loop(key, value) {
        if (value == 'id') {
          return 'continue';
        }
        listedColumns.forEach(function (group) {
          if (!selectedHeaders[key]) {
            var selected_option = group.options.find(function (element) {
              return element.name === value;
            });
            selectedHeaders[key] = selected_option;
            if (selected_option) {
              _this7.removeFromHeaderOptions(selected_option);
            }
          }
        });
      };

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.entries(headers)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _ref3 = _step2.value;

          var _ref4 = _slicedToArray(_ref3, 2);

          var key = _ref4[0];
          var value = _ref4[1];

          var _ret = _loop(key, value);

          if (_ret === 'continue') continue;
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      this.set('selectedHeaders', selectedHeaders);
    },
    removeFromHeaderOptions: function removeFromHeaderOptions(selected_option) {
      var listedColumns = this.get('listedColumns');
      listedColumns[selected_option.parent_index].options.removeObject(selected_option);
      this.set('listedColumns', listedColumns);
      this.reloadDropDown();
    },
    addToHeaderOptions: function addToHeaderOptions(selected_option) {
      var listedColumns = this.get('listedColumns');
      listedColumns[selected_option.parent_index].options.addObject(selected_option);
      this.set('listedColumns', listedColumns);
      this.reloadDropDown();
    },
    reloadDropDown: function reloadDropDown() {
      var _this8 = this;

      debounce(this, function () {
        _this8.toggleProperty('triggerDropDownReset');
      }, 10);
    },
    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },
    checkSelectedHeaders: function checkSelectedHeaders() {
      var mandatory_headers = [];
      var selectedHeaders = Object.values(this.get('selectedHeaders'));
      var listedColumns = this.get('listedColumns');
      if (!selectedHeaders.find(function (header) {
        return header.main_comment;
      })) {
        mandatory_headers.push('Main comment');
      }
      if (!selectedHeaders.find(function (header) {
        return header.main_rating;
      })) {
        mandatory_headers.push('Main question');
      }
      if (!selectedHeaders.find(function (header) {
        return header.name === 'response_date';
      })) {
        mandatory_headers.push('Feedback - Response Date');
      }
      if (!selectedHeaders.find(function (header) {
        return header.name === 'language';
      })) {
        mandatory_headers.push('Feedback - Language');
      }
      if (!selectedHeaders.find(function (header) {
        return header.name === 'email_recipient';
      }) && !selectedHeaders.find(function (header) {
        return header.name === 'phone_number_recipient';
      })) {
        mandatory_headers.push('Feedback - Recipient');
      }
      if (listedColumns[0].options.find(function (option) {
        return option.mandatory && !option.main_comment && !option.main_rating;
      })) {
        mandatory_headers.push('Required questions');
      }
      if (mandatory_headers.length > 0) {
        this.set('headerErrors', mandatory_headers);
        this.set('disableUpload', 'disabled');
        return false;
      }
      return true;
    },


    companies_segments: Ember.computed('model.company.id', function () {
      return this.get('store').query('companies-segment', { filter: { company_id: this.get('model.company.id') } });
    }),

    _searchSegment: function _searchSegment(term, resolve, reject) {
      this.get('store').query('companies-segment', {
        filter: {
          name: term,
          company_id: this.get('model.company.id')
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (segments) {
        return resolve(segments);
      }, reject);
    },
    openModalSegment: function openModalSegment(survey_id, segment_name) {
      this.set('modal_segment_phrase', 'Une survey de la m\xEAme compagnie est d\xE9j\xE0 associ\xE9 \xE0 un autre segment (' + segment_name + ') pour store. Veuillez utiliser le m\xEAme.');
      this.set('modal_segment_icon', 'warning');
      $('#segment-' + survey_id).modal('show');
    },


    actions: {
      saveSurvey: function saveSurvey() {
        this.get('model').save();
      },
      generateFeedbacks: function generateFeedbacks() {
        var _this9 = this;

        var nb_feedbacks = this.get('generate_number_feedbacks');
        var start_date = this.get('generate_start_date');
        var end_date = this.get('generate_end_date');
        if (!nb_feedbacks || !start_date || !end_date) {
          this.set('modal_phrase', 'Veuillez renseigner le nombre de feedbacks, la date de d\xE9but et la date de fin');
          this.set('modal_icon', 'warning');
          $('#generate-' + this.get('model.id')).modal('show');
          return;
        }
        if (nb_feedbacks > 100000) {
          this.set('modal_phrase', 'Veuillez renseigner un nombre de feedbacks inf\xE9rieur \xE0 100 000');
          this.set('modal_icon', 'warning');
          $('#generate-' + this.get('model.id')).modal('show');
          return;
        }
        if (start_date > end_date) {
          this.set('modal_phrase', 'La date de d\xE9but doit \xEAtre inf\xE9rieure \xE0 la date de fin');
          this.set('modal_icon', 'warning');
          $('#generate-' + this.get('model.id')).modal('show');
          return;
        }
        this.set('modal_phrase', 'Voulez vous g\xE9n\xE9rer ' + nb_feedbacks + '  feedbacks ? Vous recevrez un mail de confirmation une fois l\'op\xE9ration termin\xE9e.');
        this.set('modal_icon', 'history');
        $('#generate-' + this.get('model.id')).modal({
          onApprove: function onApprove() {
            _this9.set('generateFeedbacksLoading', true);
            _this9.get('store').unloadAll('GenerateFeedback');
            var generate_feedbacks = _this9.get('store').createRecord('GenerateFeedback');
            generate_feedbacks.survey_id = _this9.get('model.id');
            generate_feedbacks.number = nb_feedbacks;
            generate_feedbacks.start_date = start_date;
            generate_feedbacks.end_date = end_date;
            generate_feedbacks.survey_type = _this9.get('surveyType');
            generate_feedbacks.delivery_type = _this9.get('deliveryType');
            generate_feedbacks.save().then(function () {
              return _this9.set('generateFeedbacksLoading', false);
            });
          }
        }).modal('show');
      },
      resetFeedbacks: function resetFeedbacks() {
        var _this10 = this;

        this.set('modal_phrase', 'Voulez vous reset les feedbacks de cette survey? Vous recevrez un mail de confirmation une fois l\'op\xE9ration termin\xE9e.');
        this.set('modal_icon', 'history');
        $('#reset-' + this.get('model.id')).modal({
          onApprove: function onApprove() {
            _this10.set('resetFeedbacksLoading', true);
            _this10.get('store').unloadAll('ResetFeedback');
            var reset_feedbacks = _this10.get('store').createRecord('ResetFeedback');
            reset_feedbacks.survey_id = _this10.get('model.id');
            reset_feedbacks.save().then(function () {
              return _this10.set('resetFeedbacksLoading', false);
            });
          }
        }).modal('show');
      },
      saveSegmentSurvey: function saveSegmentSurvey() {
        var _this11 = this;

        if (this.get('model.store_segment.id') === undefined) {
          this.get('model').save();
          return;
        }
        var country_segment = this.get('model.country_segment');
        var store_segment = this.get('model.store_segment');
        this.get('store').query('survey', {
          filter: {
            company_id: this.get('model.company.id')
          }
        }).then(function (surveys) {
          var save = true;
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = surveys.toArray()[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var survey = _step3.value;

              if (survey.get('store_segment.id') !== undefined && survey.get('store_segment.id') !== store_segment.get('id')) {
                _this11.openModalSegment(_this11.get('model.id'), survey.get('store_segment.name'));
                save = false;
                break;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          if (save) {
            _this11.set('model.country_segment', country_segment);
            _this11.set('model.store_segment', store_segment);
            _this11.get('model').save();
          }
        });
      },
      computeText: function computeText(consentTextObject) {
        this._computeText(consentTextObject);
      },
      saveSurveyConsentText: function saveSurveyConsentText() {
        this._verifySegments();
      },
      resetBackgroundColor: function resetBackgroundColor() {
        this.set('model.custom_button_bg_color', null);
      },
      toggleTextOptionsDisplay: function toggleTextOptionsDisplay() {
        this.set('textOptionsDisplay', this.get('textOptionsDisplay') == 'hidden' ? 'visible' : 'hidden');
      },
      handleFeedbackUpload: function handleFeedbackUpload(deferred) {
        var _this12 = this;

        this.feedbackUpload().then(function () {
          deferred.resolve();
        }).catch(function (error) {
          deferred.reject(error);
        });
        deferred.promise.catch(function (error) {
          _this12.handleError(error);
        });
      },
      feedbackImport: function feedbackImport() {
        this.set('headerErrors', null);
        if (!this.checkSelectedHeaders()) {
          return;
        }
        this.set('feedbackUploadError', null);
        var headers = this.get('feedback_import.headers');
        var selectedHeaders = this.get('selectedHeaders');
        var _iteratorNormalCompletion4 = true;
        var _didIteratorError4 = false;
        var _iteratorError4 = undefined;

        try {
          for (var _iterator4 = Object.entries(selectedHeaders)[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
            var _ref5 = _step4.value;

            var _ref6 = _slicedToArray(_ref5, 2);

            var key = _ref6[0];
            var value = _ref6[1];

            headers[key] = value['name'];
          }
        } catch (err) {
          _didIteratorError4 = true;
          _iteratorError4 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion4 && _iterator4.return) {
              _iterator4.return();
            }
          } finally {
            if (_didIteratorError4) {
              throw _iteratorError4;
            }
          }
        }

        var _this = this;
        this.get('store').findRecord('feedback_import', this.get('feedback_import.id')).then(function (feedback_import) {
          feedback_import.headers = headers;
          feedback_import.save().then(function (response) {
            _this.set('feedback_import', null);
            _this.closeModal();
            _this.feedback_imports();
          }).catch(function (response) {
            var error = response;
            if (Array.isArray(response.errors)) {
              error = response.errors[0].detail.match(/>(.*)/)[0];
            }
            _this.set('feedbackImportError', error);
          });
        });
      },
      downloadFile: function downloadFile(type, id) {
        var url = _environment.default.EmberENV.API_URL + '/export/feedback-import/' + type + '/' + id + '?api_key=' + this.get('session.session.authenticated.access_token');
        window.open(url, '_blank');
      },
      changeHeaders: function changeHeaders(selected, currentOptions, context) {
        var headers = this.get('selectedHeaders');
        this.removeFromHeaderOptions(selected);
        if (headers[context.key]) {
          this.addToHeaderOptions(headers[context.key]);
        }
        headers[context.key] = selected;
        this.set('selectedHeaders', headers);
        this.set('disableUpload', '');
        this.notifyPropertyChange('selectedHeaders');
      },
      deleteFeedbackImport: function deleteFeedbackImport(feedback_import) {
        feedback_import.deleteRecord();
        feedback_import.save();
      },
      openModal: function openModal() {
        this.set('modalIsOpened', true);
      },
      closeModal: function closeModal() {
        this.get('feedback_import').deleteRecord();
        this.get('feedback_import').save();
        this.closeModal();
      },
      searchUpdated: function searchUpdated(value) {
        this.set('displayField', value === '' ? 'title' : 'name');
      },
      searchSegments: function searchSegments(term) {
        var _this13 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this13, _this13._searchSegment, term, resolve, reject, 300);
        });
      }
    }
  });
});